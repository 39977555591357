.uxf-data-grid {
    .rdg-header-row {
        color: theme("colors.lightMedium");

        :root .dark & {
            color: theme("colors.darkMedium");
        }

        .rdg-cell {
            background-color: theme("colors.gray.50");

            :root .dark & {
                color: theme("colors.gray.50");
            }
        }
    }

    .uxf-data-grid__table {
        --rdg-font-size: 16px;
    }

    &__rows-per-page {
        font-size: theme("fontSize.body");
        line-height: theme("lineHeight.6")
    }

    &__pagination-counts {
        font-size: theme("fontSize.body");
        line-height: theme("lineHeight.6")
    }

    /* skrytí "Nastavení sloupců" a "Stáhnout CSV" */
    &__plugin-button:not(:first-child) {
        display: none;
    }
}
