:root .light {
    .uxf-cms-user-menu {
        border-top-color: theme("colors.gray.900");
    }

    .uxf-cms-menu__item--super {
        color: theme("colors.primary.600");
        font-size: theme("fontSize.base");
    }

    .uxf-cms-layout__container {
        background-color: white;
    }

    .has-gray-bg {
        background-color: theme("colors.gray.100");

        .uxf-cms-layout__container {
            background-color: theme("colors.gray.100");
        }
    }
}
