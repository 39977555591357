.uxf-chip {
    --button-bg-color-hover: transparent;
    --spacing-y: calc((var(--h) - var(--button-size)) / 2);
    --spacing-x: theme("spacing.2");

    align-items: center;
    background-color: var(--bg-color);
    border-radius: theme("borderRadius.lg");
    color: var(--color);
    display: inline-flex;
    height: var(--h);
    max-width: 100%;
    padding: 0 var(--spacing-x);

    &.has-button {
        padding-right: var(--spacing-y);
    }

    &--size-default {
        --button-border-radius: var(--spacing-y);
        --button-size: 16px;
        --h: 24px;

        @apply text-sm;
    }

    &--size-small {
        --button-border-radius: 999px;
        --button-size: 16px;
        --h: 20px;

        @apply text-xs;
    }

    &--size-large {
        --button-border-radius: var(--spacing-y);
        --button-size: 20px;
        --h: 28px;

        @apply text-base;
    }

    &__text {
        @apply truncate;

        letter-spacing: -0.02em;
    }

    &__button {
        align-items: center;
        border-radius: var(--button-border-radius);
        display: inline-flex;
        flex-shrink: 0;
        height: var(--button-size);
        justify-content: center;
        margin-left: calc(var(--spacing-y) / 2);
        text-align: center;
        width: var(--button-size);

        &-label {
            @apply sr-only;
        }

        &-icon {
            height: 8px;
            width: 8px;
        }

        &:hover {
            background-color: var(--button-bg-color-hover);
            color: var(--button-color-hover);
        }
    }

    &--variant-default {
        --button-color-hover: var(--color);

        &.uxf-chip--color-orange {
            :root .light & {
                --bg-color: theme("colors.orange.600");
                --button-bg-color-hover: theme("colors.orange.700");
                --color: theme("colors.white");
            }

            :root .dark & {
                --bg-color: theme("colors.orange.700");
                --button-bg-color-hover: theme("colors.orange.900");
                --color: theme("colors.orange.100");
            }
        }

        &.uxf-chip--color-red {
            :root .light & {
                --bg-color: theme("colors.red.600");
                --button-bg-color-hover: theme("colors.red.700");
                --color: theme("colors.white");
            }

            :root .dark & {
                --bg-color: theme("colors.red.700");
                --button-bg-color-hover: theme("colors.red.900");
                --color: theme("colors.red.100");
            }
        }

        &.uxf-chip--color-yellow {
            :root .light & {
                --bg-color: theme("colors.yellow.600");
                --button-bg-color-hover: theme("colors.yellow.700");
                --color: theme("colors.white");
            }

            :root .dark & {
                --bg-color: theme("colors.yellow.700");
                --button-bg-color-hover: theme("colors.yellow.900");
                --color: theme("colors.yellow.100");
            }
        }

        &.uxf-chip--color-pink {
            :root .light & {
                --bg-color: theme("colors.pink.500");
                --button-bg-color-hover: theme("colors.pink.700");
                --color: theme("colors.white");
            }

            :root .dark & {
                --bg-color: theme("colors.pink.700");
                --button-bg-color-hover: theme("colors.pink.900");
                --color: theme("colors.pink.100");
            }
        }

        &.uxf-chip--color-purple {
            :root .light & {
                --bg-color: theme("colors.purple.500");
                --button-bg-color-hover: theme("colors.purple.700");
                --color: theme("colors.white");
            }

            :root .dark & {
                --bg-color: theme("colors.purple.700");
                --button-bg-color-hover: theme("colors.purple.900");
                --color: theme("colors.purple.100");
            }
        }

        &.uxf-chip--color-indigo {
            :root .light & {
                --bg-color: theme("colors.indigo.500");
                --button-bg-color-hover: theme("colors.indigo.700");
                --color: theme("colors.white");
            }

            :root .dark & {
                --bg-color: theme("colors.indigo.700");
                --button-bg-color-hover: theme("colors.indigo.900");
                --color: theme("colors.indigo.100");
            }
        }

        &.uxf-chip--color-green {
            :root .light & {
                --bg-color: theme("colors.green.600");
                --button-bg-color-hover: theme("colors.green.700");
                --color: theme("colors.white");
            }

            :root .dark & {
                --bg-color: theme("colors.green.700");
                --button-bg-color-hover: theme("colors.green.900");
                --color: theme("colors.green.100");
            }
        }

        &.uxf-chip--color-blue {
            :root .light & {
                --bg-color: theme("colors.blue.500");
                --button-bg-color-hover: theme("colors.blue.700");
                --color: theme("colors.white");
            }

            :root .dark & {
                --bg-color: theme("colors.blue.700");
                --button-bg-color-hover: theme("colors.blue.900");
                --color: theme("colors.blue.100");
            }
        }

        &.uxf-chip--color-default {
            :root .light & {
                --bg-color: theme("colors.gray.500");
                --button-bg-color-hover: theme("colors.gray.700");
                --color: theme("colors.white");
            }

            :root .dark & {
                --bg-color: theme("colors.gray.700");
                --button-bg-color-hover: theme("colors.gray.900");
                --color: theme("colors.gray.100");
            }
        }

        &.uxf-chip--color-primary {
            :root .light & {
                --bg-color: theme("colors.primary.500");
                --button-bg-color-hover: theme("colors.primary.700");
                --color: theme("colors.white");
            }

            :root .dark & {
                --bg-color: theme("colors.primary.700");
                --button-bg-color-hover: theme("colors.primary.900");
                --color: theme("colors.primary.100");
            }
        }
    }

    &--variant-low {
        --bg-color: transparent;
        --button-color-hover: var(--color);
        --spacing-x: calc(theme("spacing.2") - 1px);

        border: 1px solid var(--color);

        &.uxf-chip--color-orange {
            :root .light & {
                --button-bg-color-hover: theme("colors.orange.200");
                --color: theme("colors.orange.500");
            }

            :root .dark & {
                --button-bg-color-hover: theme("colors.orange.900");
                --color: theme("colors.orange.400");
            }
        }

        &.uxf-chip--color-red {
            :root .light & {
                --button-bg-color-hover: theme("colors.red.200");
                --color: theme("colors.red.500");
            }

            :root .dark & {
                --button-bg-color-hover: theme("colors.red.900");
                --color: theme("colors.red.400");
            }
        }

        &.uxf-chip--color-yellow {
            :root .light & {
                --button-bg-color-hover: theme("colors.yellow.200");
                --color: theme("colors.yellow.500");
            }

            :root .dark & {
                --button-bg-color-hover: theme("colors.yellow.900");
                --color: theme("colors.yellow.400");
            }
        }

        &.uxf-chip--color-pink {
            :root .light & {
                --button-bg-color-hover: theme("colors.pink.200");
                --color: theme("colors.pink.500");
            }

            :root .dark & {
                --button-bg-color-hover: theme("colors.pink.900");
                --color: theme("colors.pink.400");
            }
        }

        &.uxf-chip--color-purple {
            :root .light & {
                --button-bg-color-hover: theme("colors.purple.200");
                --color: theme("colors.purple.500");
            }

            :root .dark & {
                --button-bg-color-hover: theme("colors.purple.900");
                --color: theme("colors.purple.400");
            }
        }

        &.uxf-chip--color-indigo {
            :root .light & {
                --button-bg-color-hover: theme("colors.indigo.200");
                --color: theme("colors.indigo.500");
            }

            :root .dark & {
                --button-bg-color-hover: theme("colors.indigo.900");
                --color: theme("colors.indigo.400");
            }
        }

        &.uxf-chip--color-green {
            :root .light & {
                --button-bg-color-hover: theme("colors.green.200");
                --color: theme("colors.green.500");
            }

            :root .dark & {
                --button-bg-color-hover: theme("colors.green.900");
                --color: theme("colors.green.400");
            }
        }

        &.uxf-chip--color-blue {
            :root .light & {
                --button-bg-color-hover: theme("colors.blue.200");
                --color: theme("colors.blue.500");
            }

            :root .dark & {
                --button-bg-color-hover: theme("colors.blue.900");
                --color: theme("colors.blue.400");
            }
        }

        &.uxf-chip--color-default {
            :root .light & {
                --button-bg-color-hover: theme("colors.gray.200");
                --color: theme("colors.gray.500");
            }

            :root .dark & {
                --button-bg-color-hover: theme("colors.gray.900");
                --color: theme("colors.gray.400");
            }
        }

        &.uxf-chip--color-primary {
            :root .light & {
                --button-bg-color-hover: theme("colors.primary.200");
                --color: theme("colors.primary.500");
            }

            :root .dark & {
                --button-bg-color-hover: theme("colors.primary.900");
                --color: theme("colors.primary.400");
            }
        }
    }
}
