.uxf-flash-messages {
    @apply space-y-4;

    bottom: 0;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: flex-end;
    max-width: 24rem;
    overflow: hidden auto;
    padding: theme("spacing.6") theme("spacing.1") theme("spacing.6") theme("spacing.6");
    pointer-events: none;
    position: fixed;
    right: 0;
    z-index: theme("zIndex.flashMessage");

    &__permanent,
    &__dismissable {
        display: flex;
        flex-direction: column-reverse;
        overflow: hidden scroll;
        padding-right: theme("spacing.3");
        pointer-events: auto;

        &::-webkit-scrollbar {
            background-color: transparent;
            width: theme("width.2");
        }

        &::-webkit-scrollbar-thumb {
            background-color: theme("colors.gray.400");
            border-radius: theme("borderRadius.2xl");
        }
    }

    &__dismissable {
        padding-top: theme("spacing.11");

        &:hover {
            padding-top: 0;
        }
    }

    &__permanent {
        flex-shrink: 0;
        max-height: 50%;
    }

    &__divider {
        width: calc(100% - 1.5rem);
    }
}

.uxf-flash-message-wrapper {
    @apply transition-all;

    transition-duration: 300ms;

    &--enter-from {
        opacity: 0;
        transform: translateX(5rem);
    }

    &--enter-to {
        opacity: 100;
        transform: translateX(0);
    }

    &:not(:first-of-type) {
        margin-top: theme("spacing.4");

        .uxf-flash-messages__dismissable & {
            margin-top: -2.75rem;
        }
    }

    .uxf-flash-messages__dismissable & {
        &:nth-last-child(n + 5) {
            display: none;
        }

        &:nth-last-child(4) {
            opacity: 0.6;
            transform: scale(0.85);
        }

        &:nth-last-child(3) {
            opacity: 0.7;
            transform: scale(0.9);
        }

        &:nth-last-child(2) {
            opacity: 0.8;
            transform: scale(0.95);
        }
    }

    .uxf-flash-messages__dismissable:hover & {
        display: block;
        opacity: 1;
        transform: scale(1);

        &:not(:first-of-type) {
            margin-top: theme("spacing.4");
        }
    }
}

.uxf-flash-message {
    --flash-message-bg-color: transparent;
    --flash-message-bg-color-hover: transparent;
    --flash-message-color: white;

    @apply transition;

    background-color: var(--flash-message-bg-color);
    border-radius: theme("borderRadius.lg");
    color: var(--flash-message-color);
    font-size: 0.875rem;
    line-height: 1.25rem;
    min-width: 15rem;
    padding: theme("spacing.4") theme("spacing.5");
    pointer-events: auto;

    &:hover,
    &:active {
        background-color: var(--flash-message-bg-color-hover);
        cursor: pointer;
    }

    &--success {
        --flash-message-bg-color: theme("colors.success.500");
        --flash-message-bg-color-hover: theme("colors.success.700");
    }

    &--error {
        --flash-message-bg-color: theme("colors.error.500");
        --flash-message-bg-color-hover: theme("colors.error.700");
    }

    &--warning {
        --flash-message-bg-color: theme("colors.warning.500");
        --flash-message-bg-color-hover: theme("colors.warning.700");
    }

    &--info {
        --flash-message-bg-color: theme("colors.gray.500");
        --flash-message-bg-color-hover: theme("colors.gray.700");
    }
}
