.no-arrows-input {
    /* Chrome, Safari, Edge, Opera */

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        appearance: none;
        margin: 0;
    }

    /* Firefox */

    input[type="number"] {
        appearance: textfield;
    }
}

.uxf-input {
    &--size-small {
        .uxf-input__wrapper {
            font-size: theme("fontSize.body");
            line-height: theme("lineHeight.6")
        }
    }
}
